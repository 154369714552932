import styled from 'styled-components';
import { Link } from 'gatsby';
import { widthFrom, widthUntil } from '@theme/theme';
import { PATTERN } from '@constants';

const ArticlesLink = styled(Link).attrs((props) => ({
  className:
    'text-3xl leading-7 font-medium text-darkBlue hover:text-darkBlue mt-4',
}))`
  ${widthUntil.MOBILE`
      font-size: ${({ theme }) => theme.fontSizes.body};
  `}
`;

const PostsContainer = styled.div.attrs((props) => ({
  className: props.isBlog
    ? ''
    : 'mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none',
}))``;

const PostsPreviewContainer = styled.div.attrs((props) => ({
  className: props.isBlog ? 'bg-white' : 'bg-darkBlue-100',
}))`
  // ${(props) => (props.isBlog ? '' : `${PATTERN}`)}
`;

export { PostsContainer, ArticlesLink, PostsPreviewContainer };
