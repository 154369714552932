import React from 'react';
import usePosts from '@hooks/use-posts';
import Posts from '@components/Posts';
import Section from '@containers/Section';
import PropTypes from 'prop-types';
import { PostsPreviewContainer, PostsContainer } from './PostsPreview.styled';

const PostsPreview = ({ isBlog }) => {
  const posts = usePosts();
  return (
    <PostsPreviewContainer isBlog={isBlog}>
      <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-12">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl leading-9 font-extrabold text-darkBlue tracking-tight sm:text-4xl">
              Latest articles
            </h2>
            <p className="text-xl leading-7 text-gray-300">
              Peek into my learnings, experiments, thoughts, ideas and
              principles.
            </p>
          </div>
          <div className={`space-y-12 ${isBlog ? '' : ''}`}>
            <PostsContainer isBlog={isBlog}>
              {isBlog
                ? posts.map((post) => (
                    <Posts key={post.slug} post={post} isBlog />
                  ))
                : posts
                    .slice(0, 3)
                    .map((post) => <Posts key={post.slug} post={post} />)}
            </PostsContainer>
          </div>
        </div>
      </div>
    </PostsPreviewContainer>
  );
};

PostsPreview.propTypes = {
  isBlog: PropTypes.bool,
};

export default PostsPreview;
