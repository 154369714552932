import Image from 'gatsby-image';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { widthFrom, widthUntil } from '@theme/theme';

const BlogPostLeft = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  ${widthUntil.MOBILE`
      width: 100%;
  `}
`;
const BlogPostRight = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  ${widthUntil.MOBILE`
      width: 100%;
  `}
`;
const Post = styled.article.attrs((props) => ({
  className: props.isBlog
    ? 'flex flex-col md:flex-row mb-8 pb-4'
    : 'flex flex-col mx-2 rounded-lg shadow-lg overflow-hidden bg-white',
}))`
  border-bottom: ${({ isBlog, theme }) =>
    isBlog ? `2px solid ${theme.colors.whiteSmoke}` : ''};
  &:last-child {
    border-bottom: none;
  }
`;

const PostHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: ${(props) => (props.isBlog ? 0 : '10px')};
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 0 10px;
`;

const Tag = styled.span.attrs((props) => ({
  className: 'leading-5 text-base font-medium',
}))`
  color: ${({ theme }) => theme.colors.teal};
  text-transform: Capitalize;
  margin-right: 6px;
`;

const BadgeTag = styled.span.attrs((props) => ({
  className:
    'leading-5 text-sm font-medium inline-flex items-center px-3 py-0.5 rounded-full bg-teal text-white',
}))`
  text-transform: Capitalize;
  margin-right: 6px;
`;

const PostCenter = styled.div``;

const PostTitle = styled.h3.attrs((props) => ({
  className: props.isBlog
    ? 'text-3xl font-medium text-gray-900 title-font mb-2 hover:text-darkBlue'
    : 'mb-2 text-xl leading-7 font-semibold text-gray-900 hover:text-darkBlue',
}))`
  text-transform: capitalize;
  width: fit-content;
  &:hover {
    box-shadow: inset 0 -35px 0 0 ${({ theme }) => theme.colors.accentYellow};
  }
`;

const PostExcerpt = styled.p.attrs((props) => ({
  className: 'leading-relaxed text-base leading-6 text-gray-500',
}))``;

const PostDate = styled.span.attrs((props) => ({
  className: 'text-sm leading-5 text-gray-500',
}))``;

const ImageLink = styled(Link).attrs((props) => ({
  className: props.isBlog ? '' : '',
}))`
  margin: ${(props) => (props.isBlog ? '0 1rem 0 0' : '')};
  ${widthUntil.MOBILE`
      margin: ${(props) => (props.isBlog ? '0 1rem 0.5rem 0' : '')};
  `}
  &:hover {
    box-shadow: none;
  }
`;

const ImageContainer = styled(Image).attrs((props) => ({
  className: `h-64 sm:h-48 w-full ${
    props.isBlog ? 'rounded-lg' : 'rounded-t-lg'
  }`,
}))``;

const ImageWrapper = styled.div.attrs((props) => ({
  className: props.isBlog ? '' : 'px-4 py-5 sm:p-6',
}))`
  // margin: 1rem 1rem 0 0;
`;

export {
  ImageContainer,
  ImageWrapper,
  ImageLink,
  PostDate,
  PostExcerpt,
  PostTitle,
  PostCenter,
  Tag,
  BadgeTag,
  TagContainer,
  PostHeaderRow,
  Post,
  BlogPostRight,
  BlogPostLeft,
};
