import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import ReadLink from '@components/ReadLink/ReadLink';
import { truncateString } from '@utils/utilFunctions';
import {
  ImageContainer,
  ImageWrapper,
  ImageLink,
  PostDate,
  PostExcerpt,
  PostTitle,
  PostCenter,
  Tag,
  TagContainer,
  BadgeTag,
  PostHeaderRow,
  Post,
  BlogPostRight,
  BlogPostLeft,
} from './Posts.styled';

const Posts = ({ post, isBlog, ...rest }) => {
  const preview = post.excerpt.childMarkdownRemark.html;
  if (isBlog) {
    return (
      <Post key={post.slug} isBlog>
        <BlogPostLeft>
          <ImageLink to={`/${post.slug}/`} isBlog>
            {post.image ? (
              <ImageContainer
                fluid={post.image.fluid}
                alt={post.title}
                isBlog={isBlog}
              />
            ) : null}
          </ImageLink>
        </BlogPostLeft>
        <BlogPostRight>
          <PostTitle isBlog>
            <Link to={`/${post.slug}/`}>{post.title}</Link>
          </PostTitle>
          <PostHeaderRow isBlog>
            <PostDate>{post.date} — </PostDate>
            <TagContainer>
              {post.tags.map((tag) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </TagContainer>
          </PostHeaderRow>
          <PostExcerpt
            dangerouslySetInnerHTML={{
              __html: preview,
            }}
          />
          <ReadLink
            to={`/${post.slug}/`}
            text="Read this post &rarr;"
            hideRight
          />
        </BlogPostRight>
      </Post>
    );
  }
  return (
    <Post key={post.slug}>
      <ImageLink to={`/${post.slug}/`}>
        {post.image ? (
          <ImageContainer fluid={post.image.fluid} alt={post.title} />
        ) : null}
      </ImageLink>
      <div className="bg-gray-50 px-4 py-4 sm:px-6">
        {/* TODO: if i want dates just uncommen
        <PostHeaderRow>
           <PostDate>{post.date} —</PostDate>
          <TagContainer>
            {post.tags.map((tag) => (
              <BadgeTag key={tag}>
                <svg
                  className="-ml-1 mr-1.5 h-2 w-2 text-indigo-400"
                  fill="currentColor"
                  viewBox="0 0 8 8"
                />
                {tag}
              </BadgeTag>
            ))}
             {post.tags.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </TagContainer>
        </PostHeaderRow> */}
        <PostTitle>
          <Link to={`/${post.slug}/`}>{post.title}</Link>
        </PostTitle>
        <PostCenter>
          <PostExcerpt
            dangerouslySetInnerHTML={{
              __html: preview ? truncateString(preview, 140) : preview,
            }}
          />
          <ReadLink
            to={`/${post.slug}/`}
            text="Read this post &rarr;"
            hideRight
          />
        </PostCenter>
      </div>
    </Post>
  );
};
Posts.propTypes = {
  post: PropTypes.object,
  isBlog: PropTypes.bool,
};
export default Posts;
